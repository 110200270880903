import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const BolgComponent = ({ id, text, image }) => {
  const [hovered, setHovered] = useState(false);
  let {lang} = useSelector((x)=>x.languageData) 
  return (
    <div 
      className='position-relative rounded-5' 
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={{cursor: 'pointer'}}
    >
      <img className='w-100 rounded-5' src={image} alt=''/>
      <div className='rounded-5 d-flex justify-content-center align-items-center position-absolute top-0 bottom-0 end-0 start-0' style={{background:'#000824E7'}}>
        {lang==='ar'?
<div className='d-flex flex-column justify-content-center align-items-center'>
<p className='text-white m-auto w-100 text-center'>{ text}</p>
        <p className='text-white m-auto w-100 text-center'>{hovered && 'لمزيد من التفاصيل اضغط هنا'}</p>
</div>


:

<div className='d-flex flex-column justify-content-center align-items-center'>
<p className='text-white m-auto w-100 text-center'>{text}</p>
<p className='text-white m-auto w-100 text-center'>{hovered && 'For More click here'}</p>

</div>}
      </div>
    </div>
  )
}

export default BolgComponent;
