import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../Assets/logo/logo.png';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import './Navbar.css';
import { toggleLanguage } from '../../store/LanguageSlice';

const Navbar = () => {
  const { t } = useTranslation();
  const { lang } = useSelector((x) => x.languageData);
  const dispatch = useDispatch();
  
  // State variable for tracking whether navbar is open or closed
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  // Function to toggle the navbar state
  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  // Close the navbar when a link is clicked
  const closeNavbar = () => {
    setIsNavbarOpen(false);
  };

  // Close the navbar on component mount
  useEffect(() => {
    setIsNavbarOpen(false);
  }, []);

  return (
    <div className={` ${lang === 'ar' ? 'rtl' : 'ltr'} `} style={{ zIndex: 49999 }}>
      <nav style={{ backgroundColor: '#EAEDEF', zIndex: 4 }} className="navbar navbar-expand-lg pt-3">
        <div className="container-fluid w-100">
          <div className='d-flex justify-content-between'>
            <NavLink className="navbar-  " to="/" onClick={closeNavbar}>
              <img className='w-50 m-0'  src={logo} alt=''/>
            </NavLink>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={toggleNavbar}>
              <span className="navbar-toggler-icon" />
            </button>
          </div>
          <div className={`collapse navbar-collapse ${isNavbarOpen ? 'show' : ''}`} id="navbarSupportedContent">
            <ul className={`navbar-nav w-100 d-flex justify-content-evenly mb-lg-0 ${lang === 'ar' ? 'ms-auto' : 'me-auto'}`} onClick={closeNavbar}>
              <li className="nav-item">
                <NavLink className="nav-link px-4 fw-bold" aria-current="page" to="/">{t('Home')}</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link px-4 fw-bold" aria-current="page" to="/WhoAreWe">{t('Who_Are_We')}</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link px-4 fw-bold" to="/ourServices">{t("Our_Services")}</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link px-4 fw-bold" aria-current="page" to="/OurPartners">{t("Our_Partners")}</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link px-4 fw-bold" aria-current="page" to="/Blog">{t("Blog")}</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link px-4 fw-bold" aria-current="page" to="/ContactUs">{t("Contact_Us")}</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
